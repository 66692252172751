import React, { useState, useEffect, useMemo } from 'react';
// import Pagination from 'react-js-pagination';
import { Link, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPaginate from 'react-paginate';

import * as actions from '../../blogs.action';
import clock from '../../../../assets/images/clock.svg';
import previousIcon from '../../../../assets/images/arrow-left.png';
import nextIcon from '../../../../assets/images/arrow-right.png';
import { getLanguage, getPathLanguage } from '../../../../utils/params';
import favorite404 from '../../../../assets/images/notfound-icon.svg';
import markNew from '../../../../assets/images/New.svg';
import markNewJP from '../../../../assets/images/New_JP.svg';
import { isMobile } from '../../../../utils/constants';
import { history } from '../../../../utils/history';

const ListBlogs = props => {
  const language = getLanguage(useLocation());
  const [pageNo, setPageNo] = useState(1);
  const pathLanguage = getPathLanguage(useLocation());
  const params = useParams();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    let pageNumber = 1;
    let categorySlug = 'seo-all-blog';
    if (params.id || (!params.id && params.category==='all')) {
      if(params.category==='all'){
        pageNumber = parseInt(params.id || 1);
        props.fetchBlogsRequest({ language, pageSize: 6, pageIndex: pageNumber });
        props.fetchAboutIsnSaga({ language, slug: categorySlug });
      } else {
        pageNumber = parseInt(params.pageIndex || 1);
        props.fetchBlogsRequest({ language, pageSize: 6, pageIndex: pageNumber, categoryId: params.id });
      }
    } else {
      if (!isMobile) {
        window.location.href = pathLanguage + '/blogs/all';
      }
    }

    setPageNo(pageNumber);
    window.scrollTo(0, 0);
    // const scrollTo = document.querySelector('#blog-position-scroll');
    // if (scrollTo) {
    //   scrollTo.scrollIntoView({ behavior: 'smooth' });
    // }
    // eslint-disable-next-line
  }, [isMobile, params]);

  const handlePageChange = pageNumber => {
    pageNumber = parseInt(pageNumber.selected) + 1;
    setPageNo(pageNumber);

    if (params.category==='all'){
      props.fetchBlogsRequest({ language, pageSize: 6, pageIndex: pageNumber });
    } else {
      props.fetchBlogsRequest({ language, pageSize: 6, pageIndex: pageNumber, categoryId: params.id });
    }

    if ((params.category==='all' && params.id ) || params.pageIndex) {
      let path = location.pathname.substring(0,location.pathname.lastIndexOf('/')+1);
      history.push(`${path}${pageNumber}`);
    } else history.push(`${location.pathname}/${pageNumber}`);
    window.scrollTo(0, 0);
    // const scrollTo = document.querySelector('#blog-position-scroll');
    // if (scrollTo) {
    //   scrollTo.scrollIntoView({ behavior: 'smooth' });
    // }
  };

  const { blogs } = props.blogs;
  const lstTitle = {
    title1: t('blogs.news'),
    title2: t('blogs.newly_launched_properties'),
    title3: t('blogs.c_s_statistics'),
    title4: t('blogs.living_in_vietnam'),
  };

  const panagationPage = useMemo(() => {
    if (blogs) {
      return (
        <div className="pagination">
          {blogs && blogs.totalRecords && blogs.totalRecords > 6 ? (
            <ReactPaginate
              //   activePage={pageNo}
              //   itemsCountPerPage={6}
              //   totalItemsCount={blogs.totalRecords || 0}
              //   pageRangeDisplayed={4}
              //   hideFirstLastPages={true}
              //   nextPageText={<LazyLoadImage src={nextIcon} alt="" effect="blur" />}
              //   prevPageText={<LazyLoadImage src={previousIcon} alt="" effect="blur" />}
              //   activeClass={'pagination-active'}
              //   onChange={page => handlePageChange(page)}
              //   itemClass={'pagination-pager-item'}
              forcePage={pageNo - 1}
              pageCount={blogs.pageCount}
              pageRangeDisplayed={1}
              marginPagesDisplayed={isMobile ? 1 : 2}
              previousLabel={<LazyLoadImage src={previousIcon} alt="" effect="blur" />}
              nextLabel={<LazyLoadImage src={nextIcon} alt="" effect="blur" />}
              activeClassName={'pagination-active'}
              onPageChange={handlePageChange}
              pageClassName={'pagination-pager-item'}
              disabledClassName={'opacity-disable'}
            />
          ) : (
            ''
          )}
        </div>
      );
    }
    // eslint-disable-next-line
  }, [blogs, pageNo, isMobile, handlePageChange]);

  return (
    <>
      {params.category && blogs && blogs.items ? (
        <>
          <h2 className="title-blog">
            {params.category==='all' ? t('blogs.all_blogs') : lstTitle[`title${params.id}`]}
          </h2>
          <div className="lower-border-category"></div>
          <div className="blog-info">
            <div className="row">
              {blogs && blogs.items ? (
                blogs.items.length ? (
                  blogs.items.map((item, index) => {
                    if (item.blogTitle) item.blogTitle = item.blogTitle.replace('　', ' ');
                    return (
                      <div key={index} className="col-12 col-md-4 mb-4">
                        <div className="block-inner">
                          <div className="block-image">
                            <Link
                              to={`${pathLanguage}/blog/detail/${item.id}/${item.slug}`}
                              className="block-handle-click"
                            >
                              {item.isNew ? (
                                language === 'jp' ? (
                                  <img src={markNewJP} alt="" className="mark-new" />
                                ) : (
                                  <img src={markNew} alt="" className="mark-new" />
                                )
                              ) : (
                                ''
                              )}
                              <img alt="" src={item.blogThumnail} />
                              {/* <LazyLoadImage alt="" src={item.blogThumnail} effect="blur" /> */}
                            </Link>
                          </div>
                          <Link to={`${pathLanguage}/blog/detail/${item.id}/${item.slug}`}>
                            <div className="block-text blog-description">
                              <h3>{item.blogTitle}</h3>
                              <div className="created">
                                <img alt="" src={clock} />
                                {/* <LazyLoadImage alt="" src={clock} effect="blur" /> */}
                                <span>{item.date}</span>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="image-notfound">
                    <div className="not-found-message">{t('blogs.no_blog_found')}</div>
                    <img src={favorite404} className="favourite-notfound" alt="" />
                    {/* <LazyLoadImage className="favourite-notfound" alt="" src={favorite404} effect="blur" /> */}
                  </div>
                )
              ) : (
                ''
              )}
            </div>
            {panagationPage}
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  { 
    fetchBlogsRequest: actions.fetchBlogsRequest,
    fetchAboutIsnSaga: actions.fetchAboutIsnRequest
  },
dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListBlogs);
