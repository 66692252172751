import React, { useEffect } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import clock from '../../../../assets/images/clock.svg';
import * as actions from '../../blogs.action';
import { getLanguage, getPathLanguage } from '../../../../utils/params';

const RightContent = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const language = getLanguage(location);
  const pathLanguage = getPathLanguage(location);
  const id = useParams();

  useEffect(() => {
    props.fetchOtherBlogsRequest({ language });
    window.scrollTo(0, 0);
    // const scrollTo = document.querySelector('#blog-position-scroll');
    // if (scrollTo) {
    //   scrollTo.scrollIntoView({ behavior: 'smooth' });
    // }

    // eslint-disable-next-line
  }, [id]);

  const { otherBlogs } = props.blogs;

  return (
    <div className="col-12 col-md-4">
      <div className="right-sidebar">
        <h3 className="sidebar-title text-transform-capitalize jp-font-sawarabi-gothic">
          {t('blog_detail.other_blogs')}
        </h3>
        <div className="posts-list">
          {otherBlogs && otherBlogs.length
            ? otherBlogs.map((item, index) => (
                <div key={index} className="row row-custom">
                  <div className="col-12 col-md-6 mb-4">
                    <div className="block-image">
                      <Link to={`${pathLanguage}/blog/detail/${item.id}/${item.slug}`}>
                        <img className="w-100 image-blog-right" alt="" src={item.blogThumnail} />
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <div className="block-text">
                      <div className="post-title blog-title">
                        <Link className="blog-title" to={`${pathLanguage}/blog/detail/${item.id}/${item.slug}`}>
                          {item.blogTitle}
                        </Link>
                      </div>
                      <div className="created blog-time jp-bold-300">
                        <img src={clock} alt="" />{' '}
                        <span>
                          {item.date
                            ? language === 'en'
                              ? moment(new Date(item.date)).format('MMM DD, YYYY')
                              : moment(new Date(item.date)).format('YYYY-MM-DD')
                            : ''}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : ''}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOtherBlogsRequest: actions.fetchOtherBlogsRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RightContent);
