import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { history } from './utils/history';

import SwitchRouter from './router/SwitchRouter';
import { getLanguage } from './utils/params';

const App = () => {
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const setLanguage = async data => {
      if (data) {
        if (data === 'ja' && !location.pathname.startsWith('/jp')) {
          if (location.pathname.startsWith('/en')) {
            history.push(location.pathname.replace('/en', '/jp') + location.search);
          } else {
            history.push(`/jp${location.pathname}${location.search}`);
          }
        } else if (data === 'en') {
          if (!location.pathname.startsWith('/en')) {
            if (location.pathname.startsWith('/jp')) {
              history.push(location.pathname.replace('/jp', '/en') + location.search);
            }
          }
        }

        await i18n.changeLanguage(data === 'ja' ? 'jp' : 'en');
        await localStorage.setItem('language', data === 'ja' ? 'jp' : 'en');
      }
    };

    let language = '';
    const languageLocaleStorage = localStorage.getItem('language');
    const getLanguageBrowser =
      navigator.userLanguage || navigator.language || window.navigator.userLanguage || window.navigator.language;
    if (location.pathname.startsWith('/jp') || location.pathname.startsWith('/en')) {
      if (location.pathname.startsWith('/jp')) language = 'ja';
      else if (location.pathname.startsWith('/en')) language = 'en';
    } else if (languageLocaleStorage) {
      if (languageLocaleStorage === 'jp') language = 'ja';
      else language = 'en';
    } else if (getLanguageBrowser) {
      if (getLanguageBrowser === 'ja' || getLanguageBrowser.toLowerCase() === 'ja-jp') language = 'ja';
      else language = 'en';
    } else language = getLanguage(location);

    setLanguage(language);
  }, [i18n, location]);

  return <SwitchRouter />;
};

export default App;
