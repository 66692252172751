import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { debounce, get } from 'lodash';

import { getLanguage, getPathLanguage } from '../../../../utils/params';
import { ResetIcon, SearchIcon, PlusSquareIcon, MinusSquareIcon, CloseIcon } from '../../icons';
import { formatMoney } from '../../../../utils/objects';
import { getQueryString } from '../../../../utils/params';
import { Input, Select, Checkbox, RangeComponent } from '../../../../components';
import * as actions from '../../../redux/layout.action';

const SearchResidenceForm = props => {
  const { t } = useTranslation();
  const language = getLanguage(useLocation());
  const pathLanguage = getPathLanguage(useLocation());
  const { handleSubmit, register, errors, watch } = useForm();

  let residence_keyword = watch('residence_keyword', '');

  const [cityId, setCityId] = useState('');
  const [showMoreConditions, setShowMoreConditions] = useState(false);
  const [isShowMore, setIsShowMore] = useState({ key: '', value: false });
  const [units, setUnits] = useState([0, 5000]);
  const [cloneDistricts, setCloneDistricts] = useState([]);
  const [formData, setFormData] = useState({
    districts: [],
    bedrooms: [],
    projects: [],
    sizes: [],
    types: [],
    facilities: {
      buildingFacilities: [],
      interiorFacilities: [],
    },
  });

  const [displayOption, setDisplayOption] = useState({
    districts: t('home_page.search.district'),
    bedrooms: t('home_page.search.bedroom'),
    projects: t('home_page.search.building_name'),
    sizes: t('home_page.search.size_range'),
    types: t('home_page.search.property_type'),
    facilities: t('home_page.search.facilities'),
  });

  const nonAccentVietnamese =(str)=> {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng 
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
}

  const onChangeFormData = (key, value) => {
    const cloneFormData = { ...formData };

    if (Object.keys(cloneFormData).includes(key) && value.length < 5) {
      if (cloneFormData[key].includes(parseInt(value))) {
        cloneFormData[key] = cloneFormData[key].filter(item => item !== parseInt(value));
      } else cloneFormData[key].push(parseInt(value));
    } else if (Object.keys(cloneFormData.facilities).includes(key)) {
      if (cloneFormData.facilities[key].includes(value)) {
        cloneFormData.facilities[key] = cloneFormData.facilities[key].filter(item => item !== value);
      } else cloneFormData.facilities[key].push(value);
    } else {
      if (cloneFormData[key].includes(value)) {
        cloneFormData[key] = cloneFormData[key].filter(item => item !== value);
      } else cloneFormData[key].push(value);
    }

    if (cloneFormData.districts) {
      setCloneDistricts(cloneFormData.districts);
    }

    setFormData(cloneFormData);
  };

  const handleClickOutside = e => {
    if (isShowMore.value) {
      if (e.target.className && !e.target.className.toString().includes('flag-dropdown')) {
        setIsShowMore({ key: '', value: false });
      }
    }
  };

  const cityLocale = get(props, 'layout.cityActive.defaultLocationId', null) || localStorage.getItem('LOCATION_CITY');

  useEffect(() => {
    const params = { language, propertyType: 0 };

    if (cityLocale) {
      params.cityId = cityLocale;
      setCityId(cityLocale);
      props.fetchDistrictsByCityIdRequest({ cityId: cityLocale, language });
    }

    if (!props.layout.range) props.fetchRange();

    props.fetchListCondition({ language });
    props.fetchProjectsSearch({
      language,
      cityId: cityLocale,
      pageSize: 999,
      pageIndex: 1,
    });

    // eslint-disable-next-line
  }, [cityLocale]);

  const getObjectParams = values => {
    const params = {};

    params.language = language;
    params.propertyType = 0; // office = 2
    if (values.residence_keyword) params.keyword = nonAccentVietnamese(residence_keyword).trim();
    if (values.location) params.cityId = values.location;
    if (values.districts && values.districts.length) params.districtId = values.districts.toString();
    if (values.types && values.types.length) params.type = values.types.toString();
    if (values.bedrooms && values.bedrooms.length) params.bedroom = values.bedrooms.toString();
    if (values.sizes && values.sizes.length) params.size = values.sizes.toString();
    if (values.projects && values.projects.length) params.project = values.projects.toString();
    if (values.facilities && Object.keys(values.facilities).length) {
      let listBuilding = [];
      if (values.facilities.buildingFacilities && values.facilities.buildingFacilities.length) {
        listBuilding = [...values.facilities.buildingFacilities];
      }
      let listInterior = [];
      if (values.facilities.interiorFacilities && values.facilities.interiorFacilities.length) {
        listInterior = [...values.facilities.interiorFacilities];
      }

      params.facilities = [...listBuilding, ...listInterior].toString() || null;
    }

    if (units.length && units[0]) params.leasePriceMin = units[0];
    else params.leasePriceMin = residencePriceMin;
    if (units.length && units[1]) params.leasePriceMax = units[1];
    else params.leasePriceMax = units[1];
    params.pageIndex = 1;
    params.pageSize = 15;

    if (!params.facilities) delete params.facilities;

    return params;
  };

  useEffect(() => {
    if (isShowMore.value) document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };

    // eslint-disable-next-line
  }, [isShowMore]);

  useEffect(() => {
    if (cityId) {
      const params = {
        language,
        cityId,
        pageSize: 999,
        pageIndex: 1,
      };

      if (cloneDistricts.length && formData.districts.length) {
        params.districtId = cloneDistricts.toString();
        debounce(() => props.fetchProjectsSearch(params), 1000);
      }
    }

    // eslint-disable-next-line
  }, [cloneDistricts, formData]);

  const returnTextOption = (option, listData, name, defaultText) => {
    if (option.length === 1) {
      const detail = listData.find(i => i.id === option[0]);
      return detail && detail[name] ? detail[name] : defaultText;
    } else if (option.length > 1) {
      return t('home_page.search.selected');
    }
    return defaultText;
  };

  useEffect(() => {
    const textDistrict = returnTextOption(
      formData.districts,
      listDistricts,
      'districtName',
      t('home_page.search.district'),
    );
    const textTypes = returnTextOption(formData.types, types, 'name', t('home_page.search.property_type'));
    const textBedroom = returnTextOption(formData.bedrooms, bedrooms, 'name', t('home_page.search.bedroom'));
    const textBuildingName = returnTextOption(
      formData.projects,
      listProjects,
      'projectName',
      t('home_page.search.building_name'),
    );
    const textSize = returnTextOption(formData.sizes, sizes, 'name', t('home_page.search.size_range'));

    let textFacilities = t('home_page.search.facilities');
    const listFacilities = [...formData.facilities.buildingFacilities, ...formData.facilities.interiorFacilities];
    if (listFacilities.length === 1) {
      const detail = [...buildingFacilities, ...interiorFacilities].find(i => i.id === listFacilities[0]);
      textFacilities = detail && detail.name ? detail.name : t('home_page.search.facilities');
    } else if (listFacilities.length > 1) {
      textFacilities = t('home_page.search.selected');
    }

    setDisplayOption({
      ...displayOption,
      districts: textDistrict,
      bedrooms: textBedroom,
      projects: textBuildingName,
      sizes: textSize,
      types: textTypes,
      facilities: textFacilities,
    });

    const handlerUnit = setTimeout(() => {
      const params = { language, propertyType: 0 };
      if (cityId) params.cityId = cityId;
      if (formData.districts.length) params.districtId = formData.districts.toString();
      if (residence_keyword) params.keyword = residence_keyword;
      if (formData.types.length) params.type = formData.types.toString();
      if (formData.bedrooms.length) params.bedroom = formData.bedrooms.toString();
      if (formData.sizes.length) params.size = formData.sizes.toString();
      if (formData.projects.length) params.project = formData.projects.toString();

      if (formData.facilities && Object.keys(formData.facilities).length) {
        let listBuilding = [];
        if (formData.facilities.buildingFacilities && formData.facilities.buildingFacilities.length) {
          listBuilding = [...formData.facilities.buildingFacilities];
        }
        let listInterior = [];
        if (formData.facilities.interiorFacilities && formData.facilities.interiorFacilities.length) {
          listInterior = [...formData.facilities.interiorFacilities];
        }
        const facilitiesData = [...listBuilding, ...listInterior];

        if (facilitiesData.length) params.facilities = facilitiesData.toString();
      }

      params.leasePriceMin = units[0] || 0;
      params.leasePriceMax = units[1];

      props.getUnitsRequest(params);
    }, 1000);

    return () => {
      clearTimeout(handlerUnit);
    };

    // eslint-disable-next-line
  }, [formData, units, cityId, residence_keyword]);

  const onSubmit = values => {
    values = { ...values, ...formData, ...{ min: units[0], max: units[1] } };
    let params = {};
    if (values && Object.keys(values).length && formData && Object.keys(formData).length) {
      params = getObjectParams(values);
    }

    params.cityId = cityId;
    params.sort = '-leasePriceMin';
    const query = getQueryString(params);
    window.location.href = `${pathLanguage}/residences/search${query}`;
  };

  const handleOnChange = value => {
    setCityId(value);
    if (value)
      setTimeout(() => {
        props.fetchDistrictsByCityIdRequest({ cityId: value, language });
        props.fetchProjectsSearch({
          language,
          cityId: value,
          pageSize: 999,
          pageIndex: 1,
        });
        setFormData({ ...formData, districts: [], projects: [] });
      }, 1);
    // else
    //   setTimeout(() => {
    //     props.fetchDistrictsByCityIdRequest({ language });
    //     props.fetchProjectsSearch({
    //       language,
    //       cityId: value,
    //       pageSize: 999,
    //       pageIndex: 1,
    //     });
    //   }, 1);
  };

  const resetForm = () => {
    setFormData({
      districts: [],
      bedrooms: [],
      projects: [],
      sizes: [],
      types: [],
      facilities: {
        buildingFacilities: [],
        interiorFacilities: [],
      },
    });
    document.getElementsByName('residence_keyword')[0].value = '';
    setUnits([0, 5000]);

    // if (
    //   props.layout.cityActive &&
    //   props.layout.cityActive.defaultLocationId &&
    //   cityId !== props.layout.cityActive.defaultLocationId
    // ) {
    //   setCityId(props.layout.cityActive.defaultLocationId);
    //   props.fetchDistrictsByCityIdRequest({ cityId: props.layout.cityActive.defaultLocationId, language });
    // }

    // reset();
  };

  const conditions = props.layout && props.layout.listCondition ? props.layout.listCondition : {};

  const { bedrooms, sizes, types, facilities } = conditions;
  const { buildingFacilities, interiorFacilities } = facilities || {};
  const listCities = get(props, 'layout.cities', []);
  const listDistricts = get(props, 'layout.districts', []);
  const listProjects = get(props, 'layout.projectsSearch.items', []);

  const { residencePriceMax, residencePriceMin, stepResidencePrice } = props.layout.range ? props.layout.range : {};

  const listCityDropdown = useMemo(() => {
    if (listCities && listCities.length) {
      return (
        <Select
          defaultValue={cityId}
          options={listCities.map(item => ({ value: item.cityId, label: item.cityName })) || []}
          class_name="form-control"
          onChange={handleOnChange}
        />
      );
    }
    return (
      <Select
        disabled
        options={[]}
        isAll={{ value: '', label: t('home_page.search.city') }}
        class_name="form-control"
      />
    );
    // eslint-disable-next-line
  }, [listCities, cityId]);

  const listDistrictsDropdown = useMemo(() => {
    if (listDistricts && listDistricts.length) {
      return listDistricts.map((item, index) => (
        <div className="col-12 col-md-4 flag-dropdown sub-text" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            register={register({})}
            name={'buidling'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={item.districtName}
            value={item.id}
            checkItemClass="form-item"
            multiple={true}
            formName="districts"
            onChangeFormData={onChangeFormData}
            defaultValue={formData.districts}
          />
        </div>
      ));
    }
    // eslint-disable-next-line
  }, [listDistricts, formData]);

  const listTypesDropdown = useMemo(() => {
    if (types && types.length) {
      return types.map((chk, index) => (
        <div className="col-12 col-md-4 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            register={register({})}
            name={'buidling'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={chk.name}
            value={chk.id}
            formName="types"
            defaultValue={formData.types}
            checkItemClass="form-item"
            multiple={true}
            onChangeFormData={onChangeFormData}
          />
        </div>
      ));
    }

    // eslint-disable-next-line
  }, [types, formData]);

  const listBedroomsDropdown = useMemo(() => {
    if (bedrooms && bedrooms.length) {
      return bedrooms.map((chk, index) => (
        <div className="col-12 col-md-4 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            register={register({})}
            name={'buidling'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={chk.name}
            value={chk.id}
            checkItemClass="form-item"
            multiple={true}
            defaultValue={formData.bedrooms}
            onChangeFormData={onChangeFormData}
            formName="bedrooms"
          />
        </div>
      ));
    }

    // eslint-disable-next-line
  }, [bedrooms, formData]);

  const listProjectsDropdown = useMemo(() => {
    if (listProjects && listProjects.length) {
      return listProjects.map((chk, index) => (
        <div className="col-12 col-md-6 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            register={register({})}
            name={'buidling'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={chk.projectName}
            value={chk.id}
            checkItemClass="form-item"
            multiple={true}
            defaultValue={formData.projects}
            onChangeFormData={onChangeFormData}
            formName="projects"
          />
        </div>
      ));
    }
    // eslint-disable-next-line
  }, [listProjects, formData]);

  const listBuildingsDropdown = useMemo(() => {
    if (buildingFacilities && buildingFacilities.length) {
      return buildingFacilities.map((chk, index) => (
        <div className="col-12 col-md-4 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            register={register({})}
            name={'buidling'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={chk.name}
            value={chk.id}
            checkItemClass="form-item"
            multiple={true}
            defaultValue={formData.facilities.buildingFacilities}
            onChangeFormData={onChangeFormData}
            formName="buildingFacilities"
          />
        </div>
      ));
    }
    // eslint-disable-next-line
  }, [buildingFacilities, formData]);

  const listInteriorsDropdown = useMemo(() => {
    if (interiorFacilities && interiorFacilities.length) {
      return interiorFacilities.map((chk, i) => (
        <div className="col-12 col-md-4 flag-dropdown" key={i}>
          <Checkbox
            class_name="form-control flag-dropdown"
            register={register({})}
            name={'interior'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={chk.name}
            value={chk.id}
            checkItemClass="form-item"
            defaultValue={formData.facilities.interiorFacilities}
            onChangeFormData={onChangeFormData}
            formName="interiorFacilities"
          />
        </div>
      ));
    }
    // eslint-disable-next-line
  }, [interiorFacilities, formData]);

  const listSizesDropdown = useMemo(() => {
    if (sizes && sizes.length) {
      return sizes.map((chk, index) => (
        <div className="col-12 col-md-4 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            register={register({})}
            name={'size'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={chk.name}
            value={chk.id}
            checkItemClass="form-item"
            multiple={true}
            defaultValue={formData.sizes}
            onChangeFormData={onChangeFormData}
            formName="sizes"
          />
        </div>
      ));
    }
    // eslint-disable-next-line
  }, [sizes, formData]);

  const onChangeUnits = values => {
    if (values[0] < values[1]) {
      setUnits(values);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="group-control">
        <div className="row">
          <div className="col-12 order-md-2 col-md-7 mb-1">
            <div className={`slide-bar ${language === 'jp' ? 'slide-bar-jp' : ''}`}>
              <div className="rent-slider">
                <p className="rent">{t('home_page.search.rent')}</p>
              </div>
              <div className="slide-prices" style={{ marginTop: '-20px' }}>
                <div className="slide-container">
                  {/* <div className="slide-price-numner">
                    <span className="min">${formatMoney(unit.min, 0, 0)}</span>
                    <span className="max float-right">${formatMoney(unit.max, 0, 0)}</span>
                  </div> */}
                  {/* <InputRange
                    className="slider min-slider"
                    maxValue={parseInt(residencePriceMax) || 5000}
                    minValue={parseInt(residencePriceMin) || 0}
                    formatLabel={value => `$${formatMoney(value, 0, 0)}`} //-> $4,3455
                    onChange={value => {
                      if (value.min < parseInt(residencePriceMin)) value.min = parseInt(residencePriceMin);
                      else if (value.max > parseInt(residencePriceMax)) value.max = parseInt(residencePriceMax);
                      setRangeSlider(value);
                    }}
                    value={unit}
                    step={stepResidencePrice}
                  /> */}
                  <br />
                  <RangeComponent
                    maxValue={parseInt(residencePriceMax) || 5000}
                    minValue={parseInt(residencePriceMin) || 0}
                    value={units}
                    step={stepResidencePrice}
                    onChangeUnit={onChangeUnits}
                  />
                </div>
                <div className="slide-price-numner" style={{ marginTop: '-4px' }}>
                  <span className="min">${residencePriceMin && formatMoney(residencePriceMin, 0, 0)}</span>
                  <span className="max float-right">
                    ${residencePriceMax && formatMoney(residencePriceMax, 0, 0)}
                    {units[1] === parseInt(residencePriceMax) ? '~' : ''}
                  </span>
                </div>
              </div>
              <div
                className="units d-none text-md-right d-md-block"
                style={language === 'jp' ? { marginLeft: '-10px' } : {}}
              >
                <span>{props.layout && props.layout.units ? props.layout.units : 0}</span>
                <span className="units-text">{t('home_page.search.units')}</span>
              </div>
            </div>
          </div>
          <div className="col-9 order-md-1 col-md-5 mb-lg-1 mb-3">
            <Input
              class_name="form-control"
              type="text"
              placeholder={t('home_page.search.key_word_vinhomes')}
              errors={errors.residence_keyword}
              register={register({})}
              name="residence_keyword"
              id="mySearch"
            />
          </div>
          <div className="col-3 d-block d-md-none pl-0">
            <div className="units text-right">
              <div>{props.layout && props.layout.units ? props.layout.units : 0}</div>
              {/* <div className="units-text">{t('home_page.search.units')}</div> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-5">
            <div className="row">
              <div className="col-6 mb-3">{listCityDropdown}</div>
              <div className="col-6 mb-3">
                <div
                  className={`form-control select-control${
                    displayOption.districts !== t('home_page.search.district') ? ' search-selected' : ''
                  }`}
                  id="facilities"
                  onClick={() => {
                    if (listDistricts && listDistricts.length) setIsShowMore({ key: 'showMoreDistrict', value: true });
                  }}
                >
                  {displayOption.districts}
                </div>
              </div>
            </div>
            <div
              className={`facilities fade-in flag-dropdown ${
                (isShowMore.key === 'showMoreDistrict' && isShowMore.value ? 'show ' : '') || ''
              }`}
              id="fcDropDown"
            >
              <div className="row flag-dropdown">
                <div className="col-10 flag-dropdown">
                  <span className="link-red uppercase flag-dropdown">{t('home_page.search.district')}</span>
                </div>
                <div className="col-2 text-right flag-dropdown">
                  <span
                    className="close-more flag-dropdown"
                    onClick={() => {
                      setIsShowMore({ key: 'showMoreDistrict', value: false });
                    }}
                  >
                    <CloseIcon />
                  </span>
                </div>
              </div>
              <div className="row building flag-dropdown">{listDistrictsDropdown}</div>
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="row">
              <div className="col-6 mb-3">
                <div
                  className={`form-control select-control${
                    displayOption.types !== t('home_page.search.property_type') ? ' search-selected' : ''
                  }`}
                  id="facilities"
                  onClick={() => setIsShowMore({ key: 'showMoreType', value: true })}
                >
                  {displayOption.types}
                </div>
              </div>
              <div
                className={`facilities add-width fade-in flag-dropdown ${
                  (isShowMore.key === 'showMoreType' && isShowMore.value ? 'show' : '') || ''
                }`}
                id="fcDropDown"
              >
                <div className="row flag-dropdown">
                  <div className="col-10 flag-dropdown">
                    <span className="link-red uppercase flag-dropdown">{t('home_page.search.property_type')}</span>
                  </div>
                  <div className="col-2 text-right flag-dropdown">
                    <span className="close-more flag-dropdown" onClick={() => setIsShowMore({ key: '', value: false })}>
                      <CloseIcon />
                    </span>
                  </div>
                </div>
                <div className="row building flag-dropdown">{listTypesDropdown}</div>
              </div>
              <div className="col-6 mb-3">
                <div
                  className={`form-control select-control ${
                    displayOption.bedrooms !== t('home_page.search.bedroom') ? ' search-selected' : ''
                  }`}
                  id="facilities"
                  onClick={() => {
                    setIsShowMore({ key: 'showMoreBedRoom', value: true });
                  }}
                >
                  {displayOption.bedrooms}
                </div>
              </div>
              <div
                className={`facilities fade-in flag-dropdown ${
                  (isShowMore.key === 'showMoreBedRoom' && isShowMore.value ? 'show' : '') || ''
                }`}
                id="fcDropDown"
              >
                <div className="row flag-dropdown">
                  <div className="col-10 flag-dropdown">
                    <span className="link-red uppercase flag-dropdown">{t('home_page.search.bedroom')}</span>
                  </div>
                  <div className="col-2 text-right flag-dropdown">
                    <span
                      className="close-more flag-dropdown"
                      onClick={() => {
                        setIsShowMore({ key: 'showMoreBedRoom', value: false });
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </div>
                </div>
                <div className="row building flag-dropdown">{listBedroomsDropdown}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={`row ${(!showMoreConditions && 'more') || ''}`} id="moreConditions">
          <div className="col-12 col-md-5 mb-3 mb-lg-0">
            <div
              className={`form-control select-control${
                displayOption.projects !== t('home_page.search.building_name') ? ' search-selected' : ''
              }`}
              id="facilities"
              onClick={() => {
                if (listProjects && listProjects.length) setIsShowMore({ key: 'showMoreProject', value: true });
              }}
            >
              {displayOption.projects}
            </div>
            <div
              className={`facilities fade-in flag-dropdown sub-text projects-list ${
                (isShowMore.key === 'showMoreProject' && isShowMore.value ? 'show' : '') || ''
              }`}
              id="fcDropDown"
            >
              <div className="row flag-dropdown">
                <div className="col-10 flag-dropdown">
                  <span className="link-red uppercase flag-dropdown">{t('home_page.search.building_name')}</span>
                </div>
                <div className="col-2 text-right flag-dropdown">
                  <span
                    className="close-more flag-dropdown"
                    onClick={() => {
                      setIsShowMore({ key: 'showMoreProject', value: false });
                    }}
                  >
                    <CloseIcon />
                  </span>
                </div>
              </div>
              <div className="row building flag-dropdown">{listProjectsDropdown}</div>
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="row">
              <div className="col-6 mb-3">
                <div
                  className={`form-control select-control${
                    displayOption.sizes !== t('home_page.search.size_range') ? ' search-selected' : ''
                  }`}
                  id="facilities"
                  onClick={() => setIsShowMore({ key: 'showMoreSize', value: true })}
                >
                  {displayOption.sizes}
                </div>
              </div>
              <div className="col-6 mb-3">
                <div
                  className={`form-control select-control${
                    displayOption.facilities !== t('home_page.search.facilities') ? ' search-selected' : ''
                  }`}
                  id="facilities"
                  onClick={() => setIsShowMore({ key: 'showMoreFacilities', value: true })}
                >
                  {displayOption.facilities}
                </div>
              </div>
            </div>
            <div
              className={`facilities add-width fade-in flag-dropdown ${
                (isShowMore.key === 'showMoreFacilities' && isShowMore.value ? 'show' : '') || ''
              }`}
              id="fcDropDown"
            >
              <div className="row flag-dropdown">
                <div className="col-10 flag-dropdown">
                  <span className="link-red uppercase flag-dropdown">{t('home_page.search.building_facilities')}</span>
                </div>
                <div className="col-2 text-right flag-dropdown">
                  <span
                    className="close-more flag-dropdown"
                    onClick={() => setIsShowMore({ key: 'showMoreFacilities', value: false })}
                  >
                    <CloseIcon />
                  </span>
                </div>
              </div>
              <div className="row building flag-dropdown">{listBuildingsDropdown}</div>
              <div className="row interior flag-dropdown">
                <div className="col-12 flag-dropdown">
                  <span className="link-red uppercase flag-dropdown">{t('home_page.search.interior_facilities')}</span>
                </div>
              </div>
              <div className="row flag-dropdown">{listInteriorsDropdown}</div>
            </div>
            <div
              className={`facilities fade-in flag-dropdown ${
                (isShowMore.key === 'showMoreSize' && isShowMore.value ? 'show' : '') || ''
              }`}
              id="fcDropDown"
            >
              <div className="row flag-dropdown">
                <div className="col-10 flag-dropdown">
                  <span className="link-red uppercase flag-dropdown">{t('home_page.search.size_range')}</span>
                </div>
                <div className="col-2 text-right flag-dropdown">
                  <span
                    className="close-more flag-dropdown"
                    onClick={() => setIsShowMore({ key: 'showMoreSize', value: false })}
                  >
                    <CloseIcon />
                  </span>
                </div>
              </div>
              <div className="row building flag-dropdown">{listSizesDropdown}</div>
            </div>
          </div>
        </div>

        <div className="row d-block d-md-none">
          <div className="col-12">
            <div className="row align-items-center advance-search">
              <div className="col-6 col-md-7 mb-3 btn-submit">
                <button type="submit" className="button button-red button-search-form button-hover">
                  <SearchIcon /> {t('home_page.search.text_btn_search')}
                </button>
              </div>
              <div className="col-5 col-md-5 mb-3 btn-reset">
                <button
                  onClick={() => resetForm()}
                  type="button"
                  className="button button-no-border button-grayish
                          button-transparent button-search-form"
                >
                  <ResetIcon /> {t('home_page.search.reset_criteria')}
                </button>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div
                  className="more-conditions"
                  id="advance-search"
                  onClick={() => setShowMoreConditions(!showMoreConditions)}
                >
                  <span>
                    {!showMoreConditions ? <PlusSquareIcon /> : <MinusSquareIcon />}
                    <span className="text1 advance-text">
                      {!showMoreConditions ? t('home_page.search.more_filters') : t('home_page.search.less_filters')}
                    </span>
                    <span className="text1 not-show collapse-text">{t('home_page.search.less_filters')}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-none d-md-block">
          <div className="col-12">
            <div className="row">
              <div className="col-5 col-md-5">
                <div className="more-conditions more-conditions-home" id="advance-search">
                  <span onClick={() => setShowMoreConditions(!showMoreConditions)}>
                    {(!showMoreConditions && <PlusSquareIcon />) || <MinusSquareIcon />}
                    <span className="text1 advance-text">
                      {!showMoreConditions ? t('home_page.search.more_filters') : t('home_page.search.less_filters')}
                    </span>
                  </span>
                </div>
              </div>
              <div className="col-7 col-md-7">
                <div className="row align-items-center advance-search">
                  <div className="col-12">
                    <div className="btn-search-home">
                      <div className="btn-submit-home">
                        <button type="submit" className="button button-red button-search-form button-hover">
                          <SearchIcon /> {t('home_page.search.text_btn_search')}
                        </button>
                      </div>
                      <div className="btn-reset-home">
                        <button
                          onClick={() => resetForm()}
                          type="button"
                          className="button button-no-border button-grayish
                          button-transparent button-search-form"
                        >
                          <ResetIcon /> {t('home_page.search.reset_criteria')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDistrictsByCityIdRequest: actions.fetchDistrictsByCityIdRequest,
      fetchListCondition: actions.fetchListConditionRequest,
      getUnitsRequest: actions.getUnitsRequest,
      fetchProjectsSearch: actions.fetchProjectsSearchRequest,
      fetchRange: actions.fetchRangeRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchResidenceForm);
